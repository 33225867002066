// src/services/i18n.js
import { StorageService } from './storage.js';
import { ref, reactive } from 'vue';

const getBrowserLocale = () => {
  const lang = navigator.language || navigator.userLanguage;
  return lang.startsWith('es') ? 'es' : 'en-US';
};

const locale = ref(StorageService.getLocale() || getBrowserLocale());
const messages = reactive({});

function loadMessages(locale) {
  try {
    return require(`src/i18n/${locale}`).default;
  } catch (err) {
    console.error(err);
    // fallback locale
    return require(`src/i18n/en-US`).default;
  }
}

function setLocale(newLocale) {
  locale.value = newLocale;
  const newMessages = loadMessages(newLocale);
  // Clear messages object
  for (const key in messages) {
    if (Object.prototype.hasOwnProperty.call(messages, key)) {
      delete messages[key];
    }
  }
  // Populate messages object with new translations
  Object.assign(messages, newMessages);
  StorageService.setLocale(newLocale);
}

// Initialize messages with the current locale
Object.assign(messages, loadMessages(locale.value));

const i18n = {
  locale,
  messages,
  t(key, args) {
    let value = key.split('.').reduce((p, c) => p?.[c], messages);
    if (value && args) {
      const names = Object.keys(args);
      const vals = Object.values(args);
      return new Function(...names, `return \`${value}\`;`)(...vals);
    }
    return value || key;
  },
  setLocale,
};

const $t = i18n.t;
export { i18n, $t };
