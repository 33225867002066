
export const AppMenus = {
  navbarTopRight: [],
  navbarTopLeft: [],
  navbarSideLeft: [
    {
      to: '/home',
      label: 'Inicio',
      icon: 'pi pi-chart-bar text-primary',
      iconcolor: '',
      target: '',
    },
    {
      to: '/accesslog',
      label: 'Bitacora de acceso',
      icon: 'pi pi-book text-primary',
      iconcolor: '',
      target: '',
    },
    {
      to: '/accessrequests',
      label: 'Solicitudes de acceso',
      icon: 'pi pi-qrcode text-primary',
      iconcolor: '',
      target: '',
    },
    {
      to: '/employees',
      label: 'Empleados',
      icon: 'pi pi-th-large text-primary',
      iconcolor: '',
      target: '',
    },
    {
      to: '/vendors',
      label: 'Proveedores',
      icon: 'pi pi-building text-primary',
      iconcolor: '',
      target: '',
    },
    {
      to: '/bitacora',
      label: 'Bitacora de control',
      icon: 'pi pi-building text-primary',
      iconcolor: '',
      target: '',
    },
    {
      to: '/ramps-dashboard',
      label: 'Andenes Dashboard',
      icon: 'pi pi-chart-pie text-primary',
      iconcolor: '',
      target: '',
    },
    {
      to: '/carrierrequests',
      label: 'Solicitud de carrier',
      icon: 'pi pi-id-card text-primary',
      iconcolor: '',
      target: '',
    },
    {
      to: '/ramps-screen',
      label: 'Pantalla Andenes',
      icon: 'pi pi-desktop text-primary',
      iconcolor: '',
      target: '',
    },
    {
      to: '',
      label: 'Administración',
      icon: 'pi pi-cog text-primary',
      iconcolor: '',
      target: '',
      items: [
        {
          to: '/users',
          label: 'Usuarios',
          icon: 'pi pi-users',
          iconcolor: '',
          target: '',
        },
        {
          to: '/userdepts',
          label: 'Departamentos',
          icon: 'pi pi-sitemap',
          iconcolor: '',
          target: '',
        },
        {
          to: '/vendorslevels',
          label: 'Categorias de proveedor',
          icon: 'pi pi-bookmark',
          iconcolor: '',
          target: '',
        },
        {
          to: '/accessrequestpriorities',
          label: 'Catalogo Prioridades',
          icon: 'pi pi-sort-alt',
          iconcolor: '',
          target: '',
        },
        {
          to: '/accessrequeststatus',
          label: 'Estatus de Solicitudes',
          icon: 'pi pi-check-circle',
          iconcolor: '',
          target: '',
        },
        {
          to: '/userstatus',
          label: 'Estatus de Usuario',
          icon: 'pi pi-check-circle',
          iconcolor: '',
          target: '',
        },
        {
          to: '/vendorsstatus',
          label: 'Estatus de proveedor',
          icon: 'pi pi-check-circle',
          iconcolor: '',
          target: '',
        },
        {
          to: '/repsestatus',
          label: 'Estatus de REPSE',
          icon: 'pi pi-check-circle',
          iconcolor: '',
          target: '',
        },
        {
          to: '/permissions',
          label: 'Permissions',
          icon: 'pi pi-th-large text-primary',
          iconcolor: '',
          target: '',
        },
        {
          to: '/roles',
          label: 'Roles',
          icon: 'pi pi-th-large text-primary',
          iconcolor: '',
          target: '',
        },
      ],
    },
  ],

  exportFormats: {
    print: {
      label: 'Print',
      icon: 'pi pi-print',
      type: 'print',
      ext: 'print',
    },
    pdf: {
      label: 'Pdf',

      icon: 'pi pi-file-pdf',
      type: 'pdf',
      ext: 'pdf',
    },
    excel: {
      label: 'Excel',
      icon: 'pi pi-file-excel',
      type: 'excel',
      ext: 'xlsx',
    },
    csv: {
      label: 'Csv',
      icon: 'pi pi-table',
      type: 'csv',
      ext: 'csv',
    },
  },
};